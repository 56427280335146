@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    background-color: #eaeef3;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.elementContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid lightgray;
    padding: 5px;
}

.iconContainer {
    padding-top: 6px;
}

.icon {
    cursor: pointer;
    margin-left: 20px;
    font-size: 20px;
}

.listHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}

.userId {
    width: rem(350px);
}

.userName {
    width: rem(200px);
}

.userCompany {
    width: rem(200px);
}

.userEmpty {
    width: rem(80px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
}
