@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.drawer {
    flex-shrink: 0;
    color: red;
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1);

    & :global(.MuiDrawer-paper) {
        padding-top: 60px;
        width: rem(240px);
        box-sizing: border-box;
        overflow: hidden;
    }
    & :global(.MuiPaper-elevation) {
        z-index: 50;
    }
    & :global(.MuiList-root) {
        height: calc(100% - 37px);
        overflow: auto;
        overscroll-behavior: contain;
    }
    .toggleNavigation {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(20px);
        border-bottom: 1px solid lightgray;
        font-size: 20px;
    }

    .icon {
        transform: scale(1.5);
        cursor: pointer;
    }

    .navIconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(20px);
    }

    .iconVersionWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
    }

    &.drawerCollapsed {
        & :global(.MuiDrawer-paper) {
            width: rem(60px);
        }
        .navIconWrapper {
            margin-right: 0;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 60px;
}

.versionWrapper {
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 37px;
    padding: 5px 10px;
    font-size: 9px;
    color: gray;
    background: white;
    box-shadow: 10px 10px 10px grey, 0 0 15px $white;
}
