@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';
.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px) rem(90px);
}

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .header {
        width: 100%;
        font-weight: 600;
        margin: rem(10px) 0;
        &:first-of-type {
            margin-top: 0;
        }
    }

    .leftSide {
        width: calc(50% - rem(10px));
    }
    .rightSide {
        width: calc(50% - rem(10px));
    }
}

.collapse {
    width: 100%;
}

.autocompleteWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: rem(20px);
}

.autocompleteFieldValues {
    padding: rem(10px) 0;
}

.autocompleteFieldValue {
    margin: rem(5px);
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(30px);
    > label {
        position: relative;
        z-index: 900;
        display: block;
        margin-right: rem(20px);
    }
    .fileName {
        display: inline-block;
    }
}

.info {
    position: absolute;
    bottom: rem(55px);
    text-align: center;
    color: brown;
    font-weight: 500;
    width: 100%;
}

.select {
    margin-bottom: rem(20px);
}
