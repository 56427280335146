@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';

.content {
    padding-top: rem(60px);

    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1), width 225ms cubic-bezier(0, 0, 0.2, 1);

    width: calc(100% - rem(60px));
    margin-left: rem(60px);

    &.open {
        width: calc(100% - rem(240px));
        margin-left: rem(240px);
    }
}
