@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
        @include desktop {
            flex-direction: row;
        }
    }
    .customError {
        display: block;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin: 3px 14px 0 14px;
        color: $error;
    }
    .uploadButtonWrapper {
        display: flex;
        align-items: center;
    }
    @include desktop {
        width: calc(100% - 600px);
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: rem(10px);
}

.filesWrapper {
    display: flex;
    width: 100%;

    .uploadCover {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 50%;
        min-height: rem(115px);
        padding-right: rem(20px);
        border-right: 1px solid gray;
        > label {
            position: relative;
            display: block;
            margin-right: rem(20px);
        }

        .fileName {
            display: inline-block;
        }

        .audioPreview {
            width: 100%;
            margin-top: rem(20px);
        }
    }

    .uploadImage {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding-left: rem(20px);
        img {
            max-height: 300px;
            margin-right: rem(20px);

            object-fit: cover;
        }
    }
}
