@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px) rem(110px);
}

.form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .contentWrapper {
        margin-bottom: rem(20px);
    }
    @include desktop {
        flex-direction: row;
        .contentWrapper {
            padding: rem(10px);
            margin-bottom: 0;
            width: 50%;
            &:last-of-type {
                width: 100%;
            }
        }
    }
}
