@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.infoWrapper {
    display: flex;
    justify-content: space-between;
    .infoFields {
        width: calc(50% - rem(10px));
    }

    .autocompleteWrapper {
        display: flex;
        flex-direction: column;
        padding-top: rem(20px);
    }
    .autocompleteField {
        width: 500px;
    }
    .autocompleteFieldValues {
        padding: 10px 0;
    }
    .autocompleteFieldValue {
        margin: 5px;
    }

    .uploadImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - rem(10px));
        img {
            max-width: 300px;
        }
    }
}

:global(.MuiPaper-root) {
    margin-bottom: 8px;
}
