@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - rem(60px));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1);
    &.open {
        width: calc(100% - rem(240px));
    }
}
