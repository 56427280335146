@import '~sass-rem';

@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);

    background-color: #eaeef3;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.image {
    width: rem(300px);
    height: rem(200px);
    margin-right: rem(40px);

    @include no-desktop {
        margin-bottom: rem(40px);
    }

    > img {
        height: 100%;
        width: 100%;

        object-fit: cover;
    }
}

.title {
    margin-bottom: rem(20px);

    white-space: pre;
}

.chips {
    margin-right: rem(8px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
}
