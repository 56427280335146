@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    color: $white;
    background-color: $tooltip-header;
}
.generalInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: rem(30px) 0 rem(60px);
    .header {
        width: 100%;
        font-weight: 600;
        margin: rem(10px) 0;
        &:first-of-type {
            margin-top: 0;
        }
    }

    .leftSide {
        width: calc(50% - rem(10px));
    }
    .rightSide {
        width: calc(50% - rem(10px));
    }
}

.collapse {
    width: 100%;
}

.autocompleteWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: rem(20px);
}
.autocompleteFieldValues {
    padding: rem(10px) 0;
}
.autocompleteFieldValue {
    margin: rem(5px);
}
.saveButton {
    display: flex;
    flex-shrink: 0;
    text-transform: uppercase;
}
