@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';

.playlistMetadataContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.autocompleteWrapper {
    display: flex;
    flex-direction: column;
    width: calc(50% - rem(10px));
    padding-bottom: rem(20px);
}

.autocompleteFieldValues {
    padding: rem(10px) 0;
}

.autocompleteFieldValue {
    margin: rem(5px);
}

.textField {
    display: flex;
    width: calc(50% - rem(10px));
    padding-bottom: rem(20px);
}
