@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);

    background-color: #eaeef3;

    .header {
        margin-bottom: rem(12px);
        max-width: 400px;
    }

    :global(.MuiFormControl-root) {
        width: 100%;
    }
}

.form {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: rem(20px);
}

.input {
    width: 100%;
}
