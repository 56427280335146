@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';
.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px) rem(110px);

    .header {
        width: 100%;
        font-weight: 600;
        margin: rem(10px) 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
    @include desktop {
        width: calc(100% - 600px);
    }
}

.collapse {
    width: 100%;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include desktop {
        margin-right: rem(80px);
    }
    .priceRow {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        margin-top: rem(18px);
        .priceInput {
            margin-right: rem(18px);
            margin-bottom: 0;
        }
    }
}

.input,
.select {
    margin: rem(8px) 0 !important;
}

.pricesAdded {
    padding: rem(5px);
}
